import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";
import { formatDate } from "../../../utils/formatTime";

const CouponDetails = ({ title, getApiRoute }) => {
  const dispatch = useDispatch();
  const [allCoupons, setAllCoupons] = useState([]);

  useEffect(() => {
    const getCouponsList = async () => {
      dispatch(isLoading(true));
      try {
        const res = await axios.get(`${baseUrl}${getApiRoute}`);
        if (
          res.status === 200 &&
          res.data.coupons &&
          res.data.coupons.length > 0
        ) {
          setAllCoupons(res.data.coupons);
          dispatch(isLoading(false));
        } else {
          if (res.data && res.data.message) {
            dispatch(openSnackbar(res.data.message, "error"));
          } else {
            dispatch(openSnackbar("No data found", "error"));
          }
          dispatch(isLoading(false));
        }
      } catch (error) {
        dispatch(openSnackbar("something went wrong", "error"));
        console.log({ error });
        dispatch(isLoading(false));
      }
    };

    if (getApiRoute) {
      getCouponsList();
    }
  }, [getApiRoute, dispatch]);

  const handleDeleteCoupon = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this coupon?"
    );
    if (!isConfirmed) return;

    dispatch(isLoading(true));
    try {
      const res = await axios.delete(`${baseUrl}/coupon/${id}`);
      if (res.status === 200) {
        dispatch(openSnackbar("Coupon Deleted Successfully", "success"));
        setAllCoupons(
          allCoupons.map((coupon) =>
            coupon._id === id ? { ...coupon, deleted: true } : coupon
          )
        );
      } else {
        dispatch(openSnackbar("Failed to delete coupon", "error"));
      }
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(openSnackbar("something went wrong", "error"));
      console.log({ error });
      dispatch(isLoading(false));
    }
  };

  function CustomizedToolbarLayout() {
    return (
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <Box width="100%" sx={{ p: 2 }}>
            <GridToolbarQuickFilter
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "rgba(158, 158, 158, 1)",
                  borderRadius: "10px",
                  borderColor: "rgba(158, 158, 158, 1)",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"end"}>
          <CustomToolbar />
        </Grid>
      </Grid>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "index", headerName: "Sr No.", width: 90 },
    { field: "couponCode", headerName: "Coupon Code", width: 150 },
    { field: "discountType", headerName: "Discount Type", width: 150 },
    { field: "discount", headerName: "Discount", width: 100 },
    {
      field: "validTill",
      headerName: "Valid Till",
      width: 150,
      valueGetter: (params) => formatDate(params.row.validTill), // Use formatDate function
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          disabled={params.row.deleted}
          sx={{ fontSize: "0.75rem" }}
          onClick={() => handleDeleteCoupon(params.row._id)}
        >
          {params.row.deleted ? "Deleted" : "Delete"}
        </Button>
      ),
    },
  ];

  const allRowDataIncludingIndex =
    allCoupons &&
    allCoupons.map((row, index) => ({
      ...row,
      index: index + 1,
    }));

  return (
    <>
      <Stack>
        <Typography variant="h4">{title}</Typography>
      </Stack>
      {allCoupons.length > 0 ? (
        <Box sx={{ minHeight: 400, width: "100%" }}>
          <DataGrid
            rows={allRowDataIncludingIndex}
            columns={columns}
            getRowId={(row) => row._id}
            slots={{ toolbar: CustomizedToolbarLayout }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            sx={{
              minHeight: "250px",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
      ) : (
        "No Data Found"
      )}
    </>
  );
};

export default CouponDetails;
