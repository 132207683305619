import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import { baseUrl } from "../../../utils/apiUrl";

const CreateCoupon = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [couponValues, setCouponValues] = useState({
    validTill: new Date().toISOString().slice(0, 16),
  });

  const handleChangeCouponValues = (e) =>
    setCouponValues({ ...couponValues, [e.target.name]: e.target.value });

  const handleSubmitCouponForm = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`${baseUrl}/coupon`, couponValues);
      if (res.status === 200) {
        dispatch(openSnackbar("Coupon Created Successfully", "success"));
        navigate("/coupon");
      }

      dispatch(isLoading(false));
    } catch (error) {
      dispatch(openSnackbar("something went wrong.", "error"));
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Create Coupon | NFC-Touch-and-Go </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            variant="h4"
            sx={{ textTransform: "uppercase" }}
            color={"primary"}
            gutterBottom
          >
            Add New Coupon
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/coupon")}
            startIcon={<Iconify icon="raphael:users" />}
          >
            All Coupons
          </Button>
        </Stack>
        <Card>
          <Box component={"form"} onSubmit={handleSubmitCouponForm}>
            <CardContent>
              <Typography variant="h4" sx={{ color: "grey" }} mb={1}>
                Coupon Info <Iconify icon="mingcute:information-fill" />{" "}
              </Typography>
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                rowGap={2.5}
                columnGap={3}
                mb={2}
              >
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="couponCode"
                    required
                    fullWidth
                    label="Coupon Code"
                    value={couponValues?.couponCode || ""}
                    onChange={handleChangeCouponValues}
                    size="small"
                    placeholder="Enter Coupon Code"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="solar:coupon-linear" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    select
                    name="discountType"
                    label="Discount Type"
                    size="small"
                    value={couponValues?.discountType || ""}
                    onChange={handleChangeCouponValues}
                    placeholder="Enter Discount Type"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="clarity:discount-line" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="percentage">Percentage</MenuItem>
                    <MenuItem value="flat">Flat</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="discount"
                    label="Discount"
                    size="small"
                    type="number"
                    value={couponValues?.discount || ""}
                    onChange={handleChangeCouponValues}
                    placeholder="Enter Discount"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="mdi:percent" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="validTill"
                    label="Valid Till"
                    size="small"
                    type="datetime-local"
                    value={couponValues?.validTill || ""}
                    onChange={handleChangeCouponValues}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="usageLimit"
                    label="Usage Limit"
                    size="small"
                    type="number"
                    value={couponValues?.usageLimit || ""}
                    onChange={handleChangeCouponValues}
                    placeholder="Enter Usage Limit"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="mdi:counter" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Stack mt={2} direction={"row"}>
                <Button type="submit" variant="contained" color="primary">
                  Create
                </Button>
              </Stack>
            </CardContent>
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateCoupon;
