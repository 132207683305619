import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { MuiTelInput } from "mui-tel-input";

import { useDispatch } from "react-redux";

import axios from "axios";

import { isLoading, openSnackbar } from "../redux/action/defaultActions";

import Iconify from "../components/iconify";

import { baseUrl } from "../utils/apiUrl";
import { googleMapApiKey } from "../utils/keys";

const CreateVendor = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [vendorValues, setVendorValues] = useState({});

  const [confirmLolcation, setConfirmLolcation] = useState(false);

  const [phone, setPhone] = React.useState("");

  const [map, setmap] = useState(null);

  const handleChange = (newPhone) => {
    setPhone(newPhone);
  };

  const handleChangeVendorValues = (e) =>
    setVendorValues({ ...vendorValues, [e.target.name]: e.target.value });

  // Initial position for the map
  const center = {
    lat: 4.2105,
    lng: 101.9758,
  };

  const handleSubmitVendorForm = async (e) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const res = await axios.post(`${baseUrl}/vendor/store/registration`, {
        ...vendorValues,
        contact: Number(phone.slice(-11).replace(/\s/g, "")),
        countryCode: Number(
          phone.slice(-(phone.length - 1), -11).replace(/\s/g, "")
        ),
        location: [selectedLocation?.lng, selectedLocation?.lng],
      });
      console.log({ res });
      console.log("submitted");
      navigate("/vendor");
      dispatch(isLoading(false));
    } catch (error) {
      if (error.response.data.message) {
        dispatch(openSnackbar(error.response.data.message, "error"));
      } else if (error.data.message) {
        dispatch(openSnackbar(error.data.message, "error"));
      } else if (error.message) {
        dispatch(openSnackbar(error.message, "error"));
      } else {
        dispatch(
          openSnackbar("something went wrong please contact support.", "error")
        );
      }
      dispatch(isLoading(false));
      console.log("error=>", error);
    }
  };

  const handleMarkerClick = (event) => {
    console.log("====>", event);
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setConfirmLolcation(false);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log("pppp==>", position.coords);
      setSelectedLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
    if (map && selectedLocation) {
      map.panTo(selectedLocation);
    }
  }, [map, selectedLocation]);

  const handleGoToMarkedLocation = async () => {
    const { address, state, city, country, zipCode, plotnumber } = vendorValues;

    if (!address || !state || !city || !country || !zipCode || !plotnumber) {
      dispatch(openSnackbar("All address fields are required", "error"));
      return;
    }

    const fullAddress = `${plotnumber} ${address}, ${city}, ${state}, ${country}, ${zipCode}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          fullAddress
        )}&key=${googleMapApiKey}`,
        { withCredentials: false }
      );

      if (response.data.results.length === 0) {
        throw new Error("No results found for the given address");
      }
      const { location } = response.data.results[0].geometry;
      setSelectedLocation({ lat: location.lat, lng: location.lng });
      map.panTo({ lat: location.lat, lng: location.lng });
    } catch (error) {
      dispatch(openSnackbar("Failed to get location from address", "error"));
      console.error("Geocoding error:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Create Vendor | NFC-Touch-and-Go </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography
            variant="h4"
            sx={{ textTransform: "uppercase" }}
            color={"primary"}
            gutterBottom
          >
            Add New Vendor
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/vendor")}
            startIcon={<Iconify icon="raphael:users" />}
          >
            All Vendors
          </Button>
        </Stack>
        <Card>
          <Box component={"form"} onSubmit={handleSubmitVendorForm}>
            <CardContent>
              <Typography variant="h4" sx={{ color: "grey" }} mb={1}>
                Vendor Info <Iconify icon="mingcute:information-fill" />{" "}
              </Typography>
              <Grid
                container
                display={"flex"}
                justifyContent={"space-between"}
                rowGap={2.5}
                columnGap={3}
                mb={2}
              >
                <Grid item xs={12} md={5.5}>
                  <TextField
                    name="name"
                    required
                    fullWidth
                    label="Vendor Name"
                    value={vendorValues?.name ? vendorValues?.name : ""}
                    onChange={handleChangeVendorValues}
                    size="small"
                    placeholder="Enter Vendor Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="solar:user-linear" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="email"
                    type="email"
                    label="Email"
                    size="small"
                    value={vendorValues?.email ? vendorValues?.email : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Email of Vendor"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="clarity:email-line" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <MuiTelInput
                    fullWidth
                    required
                    label="Mobile Number"
                    defaultCountry="MY"
                    size="small"
                    value={phone}
                    placeholder="Enter Phone Number"
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="iconamoon:phone-thin" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="plotnumber"
                    label="Plot/Building Number"
                    size="small"
                    value={
                      vendorValues?.plotnumber ? vendorValues?.plotnumber : ""
                    }
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Plot/Building Number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="mdi:land-plots-circle" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="address"
                    label="Address"
                    size="small"
                    value={vendorValues?.address ? vendorValues?.address : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter Address"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="gis:position-o" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="city"
                    label="City"
                    size="small"
                    value={vendorValues?.city ? vendorValues?.city : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter City Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="healthicons:city-outline" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="state"
                    label="State"
                    size="small"
                    value={vendorValues?.state ? vendorValues?.state : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Enter State"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="streamline:shopping-building-real-home-tower-building-house-estate" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="country"
                    label="Country"
                    size="small"
                    value={vendorValues?.country ? vendorValues?.country : ""}
                    onChange={handleChangeVendorValues}
                    placeholder="Country Name"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="solar:flag-linear" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    name="zipCode"
                    label="Pincode / Zipcode"
                    size="small"
                    type="number"
                    value={vendorValues?.zipCode ? vendorValues?.zipCode : ""}
                    onChange={(e) => handleChangeVendorValues(e)}
                    placeholder="Enter Zipcode"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="ri:file-zip-line" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  <TextField
                    fullWidth
                    required
                    name="oneTimePassword"
                    label="Create One Password"
                    size="small"
                    value={
                      vendorValues?.oneTimePassword
                        ? vendorValues?.oneTimePassword
                        : ""
                    }
                    onChange={handleChangeVendorValues}
                    placeholder="Create Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="vaadin:password" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={1}
              >
                <Typography variant="h6">
                  Store Location on map / Google Map position
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ mt: 2 }}
                  onClick={handleGoToMarkedLocation}
                >
                  go to marked location
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ mt: 2 }}
                  startIcon={<Iconify icon="carbon:map" />}
                  onClick={() => {
                    if (selectedLocation) {
                      setConfirmLolcation(true);
                      console.log("confirm Location:", selectedLocation);
                    } else {
                      alert("Please select a location on the map.");
                    }
                  }}
                >
                  confirm Location
                </Button>
              </Stack>
              <Box>
                <LoadScript googleMapsApiKey={googleMapApiKey}>
                  <GoogleMap
                    center={center}
                    zoom={18}
                    mapContainerStyle={{ width: "100%", height: "400px" }}
                    onLoad={(map) => setmap(map)}
                    onClick={handleMarkerClick}
                    draggable={false}
                  >
                    {selectedLocation && (
                      <Marker
                        position={selectedLocation}
                        title={`${vendorValues?.plotnumber} ${vendorValues?.address} ${vendorValues?.city} , ${vendorValues?.state} ${vendorValues?.country} , ${vendorValues?.zipCode}`}
                        icon={{
                          url: "../assets/icons/chipt_map_icon.svg",
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </Box>

              <Stack mt={2} direction={"row"}>
                <Button
                  disabled={!confirmLolcation}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Create
                </Button>
              </Stack>
            </CardContent>
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default CreateVendor;
