// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/chiptadmin/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/app",
    icon: icon("ic_analytics"),
  },
  {
    title: "vendor",
    path: "/vendor",
    icon: icon("ic_vendor"),
  },
  {
    title: "customers",
    path: "/customers",
    icon: icon("ic_customer"),
  },
  {
    title: "package management",
    path: "/packages",
    icon: icon("ic_coffe_mug"),
  },
  {
    title: "cup management",
    path: "/cups",
    icon: icon("ic_coffe_mug"),
  },
  {
    title: "vendor stocks",
    path: "/vendor-stock",
    icon: icon("ic_stock"),
  },
  {
    title: "orders",
    path: "/orders",
    icon: icon("ic_orders"),
  },
  // {
  //   title: "Generate List",
  //   path: "/generatelist",
  //   icon: icon("ic_list"),
  // },
  {
    title: "Generate Cup Models",
    path: "/generatecupmodels",
    icon: icon("ic_cupmodels"),
  },
  {
    title: "All added cups",
    path: "/allcups",
    icon: icon("ic_coffecup1"),
  },
  {
    title: "Scan To Add Cups",
    path: "/scan-to-add-cup",
    icon: icon("ic_nfcscan"),
  },
  {
    title: "coupon",
    path: "/coupon",
    icon: icon("ic_coupon"),
  },
  //
  // ic_coffe_mug
  // ic_customer
  // {
  //   title: 'product',
  //   path: '/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
