import { useSelector } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import GenerateResetOtp from "./components/forgetpassword/GenerateResetOtp";
import UpdatePassword from "./components/forgetpassword/UpdatePassword";
import VerifyEmailToGetResetOtp from "./components/forgetpassword/VerifyEmailToGetResetOtp";
import GenerateCupModels from "./components/generatecupmodels/GenerateCupModels";
import CreateVendor from "./pages/CreateVendor";
import DashboardAppPage from "./pages/DashboardAppPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProductsPage from "./pages/ProductsPage";
import ProfilePage from "./pages/ProfilePage";
import CouponMainPage from "./sections/@dashboard/coupon/CouponMainPage";
import CreateCoupon from "./sections/@dashboard/coupon/CreateCoupon";
import AddNewCup from "./sections/@dashboard/cups/AddNewCup";
import BulkUploadCups from "./sections/@dashboard/cups/BulkUploadCups";
import CupDetailPage from "./sections/@dashboard/cups/CupDetailPage";
import CupsMgtHome from "./sections/@dashboard/cups/CupsMgtHome";
import DisplayAllCups from "./sections/@dashboard/cups/DisplayAllCups";
import QrCodeScanner from "./sections/@dashboard/cups/QrCodeScanner";
import ScanAndAddCup from "./sections/@dashboard/cups/ScanAndAddCup";
import CustomerMainPage from "./sections/@dashboard/customer/CustomerMainPage";
import CustomerProfilePage from "./sections/@dashboard/customer/CustomerProfilePage";
import OrdersMainPage from "./sections/@dashboard/orders/OrdersMainPage";
import SingleOrderRequestDetail from "./sections/@dashboard/orders/SingleOrderRequestDetail";
import AddNewPackage from "./sections/@dashboard/packages/AddNewPackage";
import PackagesMgtHome from "./sections/@dashboard/packages/PackagesMgtHome";
import UpdatePackage from "./sections/@dashboard/packages/UpdatePackage";
import AllVendorStocks from "./sections/@dashboard/stocks/AllVendorStocks";
import SingleVendorStock from "./sections/@dashboard/stocks/SingleVendorStock";
import VendorMainPage from "./sections/@dashboard/vendor/VendorMainPage";
import VendorProfile from "./sections/@dashboard/vendor/VendorProfile";

// ----------------------------------------------------------------------

export default function Router() {
  const { nfcTngAdmin } = useSelector((state) => state);

  //

  const routes = useRoutes([
    nfcTngAdmin?.isAuthenticated && nfcTngAdmin?.isUser
      ? {
          path: "/",
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/app" />, index: true },
            { path: "/app", element: <DashboardAppPage /> },
            { path: "/vendor", element: <VendorMainPage /> },
            { path: "/vendor/create", element: <CreateVendor /> },
            { path: "/coupon", element: <CouponMainPage /> },
            { path: "/coupon/create", element: <CreateCoupon /> },
            {
              path: "/vendor/vendorprofile/:vendorId",
              element: <VendorProfile />,
            },
            { path: "/customers", element: <CustomerMainPage /> },
            {
              path: "/customers/customerProfile/:customerId",
              element: <CustomerProfilePage />,
            },

            {
              path: "/packages",
              element: <PackagesMgtHome />,
            },

            {
              path: "/packages/add",
              element: <AddNewPackage />,
            },

            {
              path: "/packages/:id",
              element: <UpdatePackage />,
            },

            {
              path: "/cups",
              element: <CupsMgtHome />,
            },
            {
              path: "/cups/add",
              element: <AddNewCup />,
            },
            {
              path: "/cups/:cupModel",
              element: <CupDetailPage />,
            },
            {
              path: "/vendor-stock",
              element: <AllVendorStocks />,
            },
            {
              path: "/vendor-stock/single-vendor-stock/:vendorId",
              element: <SingleVendorStock />,
            },
            { path: "/products", element: <ProductsPage /> },
            { path: "/orders", element: <OrdersMainPage /> },
            { path: "/orders/:orderId", element: <SingleOrderRequestDetail /> },
            { path: "/profile", element: <ProfilePage /> },
            { path: "/allcups", element: <DisplayAllCups /> },
            // { path: "/generatelist", element: <GenerateListMainPage /> },
            { path: "/generatecupmodels", element: <GenerateCupModels /> },
            { path: "/scan-to-add-cup", element: <ScanAndAddCup /> },
            { path: "/bulkupload", element: <BulkUploadCups /> },
            { path: "/temp", element: <QrCodeScanner /> },

            // { path: "/blog", element: <BlogPage /> },
            { path: "/privacy-policy", element: <PrivacyPolicy /> },
          ],
        }
      : {
          path: "/",
          element: <SimpleLayout />,
          children: [
            { element: <Navigate to="/login" />, index: true },
            { path: "404", element: <Page404 /> },
            { path: "404", element: <Page404 /> },
            { path: "*", element: <Navigate to="/login" /> },
          ],
        },

    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/forget-password",
      element: <VerifyEmailToGetResetOtp />,
    },
    {
      path: "/forget-password/otp-verification",
      element: <GenerateResetOtp />,
    },
    {
      path: "/forget-password/update-password",
      element: <UpdatePassword />,
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
