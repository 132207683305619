import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Iconify from "../../../components/iconify/Iconify";
import CouponDetails from "./CouponDetails";

const CouponMainPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Admin | All Coupons </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Coupons
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate("/coupon/create")}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Coupon
          </Button>
        </Stack>
        <Stack>
          <Box sx={{ width: "100%" }}>
            <CouponDetails title="All Coupons" getApiRoute={"/coupon"} />
          </Box>
        </Stack>
      </Container>
    </>
  );
};

export default CouponMainPage;
